<template>
    <div :class="['wit-textarea']" :data-selected="internalValue" :id="id">
        <b-form-textarea
            v-model="internalValue"
            :placeholder="placeholder"
            :disabled="disabled"
            :readonly="readonly"
            :rows="rows"
            :max-rows="maxRows"
            @input="emitInput"
        >
            <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
                ><slot :name="slot" v-bind="scope"
            /></template>
        </b-form-textarea>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            required: true,
        },
        id: {
            type: String,
        },
        placeholder: {
            type: String,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        rows: {
            type: [String, Number],
        },
        maxRows: {
            type: [String, Number],
        },
    },
    watch: {
        value() {
            this.internalValue = this.value
        },
    },
    created() {
        if (this.value) {
            this.internalValue = this.value
        }
    },
    data() {
        return {
            internalValue: null,
        }
    },
    methods: {
        emitInput() {
            this.$emit('input', this.internalValue)
        },
    },
}
</script>

<style lang="scss"></style>
